<template>
    <div>
        <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col cols="12" :md="headToHeadExpanded ? 9 : 6">
                <!-- HEAD TO HEAD PIE -->
                <stats-card v-if="showAverage" @secondTitleActive="showAverage = false" title="Pie" secondTitle="Bar" @expand="toggleHeadToHeadExpanded" :defaultOpenOptions="true">
                    <template slot="headerActions">
                    <v-row>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'" class="d-flex align-center">
                            <div>
                                <h5>Wins</h5>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                        :items="overallWinnerYearFromFilters"
                                        v-model="overallWinnerYearFromFilter"
                                        @change="overallWinnerYearFromFilterChanged"
                                        label="Year from"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                        :items="overallWinnerYearToFilters"
                                        v-model="overallWinnerYearToFilter"
                                        @change="overallWinnerYearToFilterChanged"
                                        label="Year to"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                            </div>
                        </v-col>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                            <div>
                                <v-combobox
                                        :items="overallCompetitionOptions"
                                        v-model="overallCompetitionFilter"
                                        @change="overallCompetitionFilterChanged"
                                        item-text="label"
                                        item-value="value"
                                        label="Competition"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                    <template slot="chart">
                        <div id="head-to-pie-chart">
                            <apexchart
                                type="pie"
                                height="650"
                                :options="overallPieChartOptions"
                                :series="overallPieChartTotals"
                            />
                        </div>
                    </template>
                </stats-card>
                <!-- HEAD TO HEAD BAR -->
                <stats-card v-else @titleActive="showAverage = true" title="Pie" secondTitle="Bar"  @expand="toggleHeadToHeadExpanded">
                    <template slot="headerActions">
                        <v-row>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'" class="d-flex align-center">
                            <div>
                                <h5>Wins</h5>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                        :items="overallWinnerYearFromFilters"
                                        v-model="overallWinnerYearFromFilter"
                                        @change="overallWinnerYearFromFilterChanged"
                                        label="Year from"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                        :items="overallWinnerYearToFilters"
                                        v-model="overallWinnerYearToFilter"
                                        @change="overallWinnerYearToFilterChanged"
                                        label="Year to"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                            </div>
                        </v-col>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                            <div>
                                <v-combobox
                                        :items="overallCompetitionOptions"
                                        v-model="overallCompetitionFilter"
                                        @change="overallCompetitionFilterChanged"
                                        item-text="label"
                                        item-value="value"
                                        label="Competition"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                    <template slot="chart">
                        <div id="head-to-head-bar-chart" class="chartBackground">
                            <apexchart
                                type="bar"
                                :options="barChartOptions"
                                :series="[{
                                    name: 'Count',
                                    data: overallPieChartTotals
                                }]"
                            ></apexchart>
                        </div>
                    </template>
                </stats-card>
            </v-col>
            <v-col cols="12" :md="overallExpanded ? 9 : 6">
                <!-- OVERALL TABLE -->
                <stats-card v-if="showOverall" @secondTitleActive="showOverall = false" title="Table" secondTitle="Line" @expand="toggleOverallExpanded" :defaultOpenOptions="true">
                    <template slot="headerActions">
                    <v-row>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'" class="d-flex align-center">
                            <div>
                                <h5>Totals</h5>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                            <div>
                                <v-combobox
                                    :items="getWinningTeamsHurling"
                                    v-model="overallTeamSelections"
                                    @change="overallTeamSelectionsChanged"
                                    multiple
                                    placeholder="All"
                                    label="Teams"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                >
                                <template #selection="{ item }">
                                    <span v-if="overallTeamSelections.length === 1">{{ item.name }}</span>
                                    <span v-else-if="item.name == overallTeamSelections[0].name">{{ overallTeamSelections.length }} teams</span>
                                </template>
                                </v-combobox>
                            </div>
                        </v-col>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                        :items="overallWinnerYearFromFilters"
                                        v-model="overallTotalsYearFromFilter"
                                        @change="overallTotalsYearFromFilterChanged"
                                        label="Year from"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                            </div>
                        </v-col>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                        :items="overallWinnerYearToFilters"
                                        v-model="overallTotalsYearToFilter"
                                        @change="overallTotalsYearToFilterChanged"
                                        label="Year to"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                    <template slot="chart">
                        <div id="overall-table">
                            <v-data-table
                                :headers="overallTableHeaders"
                                :items="getWinningResults"
                                sort-by="AllIrelandWins"
                                sort-desc
                                class="elevation-1"
                                mobile-breakpoint="0">

                                <template v-slot:item.team="{item}">
                                    <span style="display: flex;">
                                        <v-img
                                        :src="`/assets/gaa/teams/icons/${item.team.code}.WEBP`"
                                        contain
                                        max-width="30"
                                        class="mr-2 img_border"
                                        ></v-img>
                                        {{ item.team.name }}
                                    </span>
                                </template>

                            </v-data-table>
                        </div>
                    </template>
                </stats-card>
                <!-- OVERALL LINE -->
                <stats-card v-else @titleActive="showOverall = true" title="Table" secondTitle="Line"  @expand="toggleOverallExpanded">
                    <template slot="headerActions">
                        <v-row>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'" class="d-flex align-center">
                            <div>
                                <h5>Totals</h5>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '4' : '12'">
                            <div>
                                <v-combobox
                                    :items="getWinningTeamsHurling"
                                    v-model="overallTeamSelections"
                                    @change="overallTeamSelectionsChanged"
                                    multiple
                                    placeholder="All"
                                    label="Teams"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                >
                                <template #selection="{ item }">
                                    <span v-if="overallTeamSelections.length === 1">{{ item.name }}</span>
                                    <span v-else-if="item.name == overallTeamSelections[0].name">{{ overallTeamSelections.length }} teams</span>
                                </template>
                                </v-combobox>
                            </div>
                        </v-col>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                        :items="overallWinnerYearFromFilters"
                                        v-model="overallTotalsYearFromFilter"
                                        @change="overallTotalsYearFromFilterChanged"
                                        label="Year from"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                            </div>
                        </v-col>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                        :items="overallWinnerYearToFilters"
                                        v-model="overallTotalsYearToFilter"
                                        @change="overallTotalsYearToFilterChanged"
                                        label="Year to"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                    <template slot="chart">
                        <div id="basicArea-chart" class="chartBackground">
                            <apexchart
                                type="line"
                                :options="lineChartOptions"
                                :series="getWinningResultsLineData.data"
                            ></apexchart>
                        </div>
                    </template>
                </stats-card>
            </v-col>
        </v-row>
    </div>
  </template>
  
  <script>
  import StatsCard from '@/components/card/statsCard/StatsCard'
import { mapActions, mapGetters } from 'vuex';
  export default {
    name: 'FootballTeamHonours',
    components: {
        StatsCard
    },
    data() {
      return {
        loading: false,
        showAverage: true,
        showOverall: true,
        headToHeadExpanded: false,
        overallExpanded: false,
        overallTeamSelections: [],
        overallWinnerYearFromFilter: 'All',
        overallWinnerYearToFilter: 'All',
        overallTotalsYearFromFilter: 'All',
        overallTotalsYearToFilter: 'All',
        overallCompetitionFilter: 'All Ireland',
        overallTableHeaders: [
          {
            text: 'Team',
            sortable: true,
            value: 'team'
          },
          { text: 'Provincial Runner Up', value: 'provincialRunnerUp', sortable: true },
          { text: 'Provincial Win', value: 'provincialWins', sortable: true },
          { text: 'All Ireland Runner Up', value: 'AllIrelandRunnerUp', sortable: true },
          { text: 'All Ireland Win', value: 'AllIrelandWins', sortable: true }

        ],
    }
    },
    computed: {
        ...mapGetters([
            "getWinners", "getWinningTeamsHurling", "getWinnersNames", "getWinnersCodes", "getCompetitionWinnerTotals", 
            "getWinningResults", "getWinningResultsLineData"
        ]),
        overallPieChartOptions() { 
            return {
                title: {
                    text: 'Titles',
                    align: 'left',
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#666'
                    }
                },
                subtitle: {
                    text: 'Know The Game',
                    align: 'left',
                    style: {
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: '#666'
                    }
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        color: '#000'
                    }
                },
                chart: {
                    type: 'pie',
                },
                fill: {
                    type: 'image',
                    opacity: 0.85,
                    image: {
                        src: this.overallPieChartImages,
                        width: 400,
                        height: 400                    
                    },
                },
                export: {
                    enabled: true,
                    // Include download options in the menu
                    menu: {
                    download: ['png', 'svg']
                    }
                },
                colors: this.overallPieChartColours,
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        startAngle: 0,
                    }
                },
                labels: this.overallPieChartLabels,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            }
        },
        barChartOptions() { return {
            chart: {
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    distributed: true, // Enable distributed mode for column charts
                    horizontal: false
                }
            },
            colors: this.overallPieChartColours,
            xaxis: {
                categories: this.overallPieChartLabels,
            }
        }
        },
        overallCompetitionOptions(){
            let stages = [];
            stages.push(...this.$store.getters['getWinners'].map(it => it.tournament));
            return stages;
        },
        overallPieChartLabels(){
            let labels = [];
            for(const key in this.$store.getters['getCompetitionWinnerTotals']){
                if(this.$store.getters['getWinnersNames'][key]){
                    labels.push(this.$store.getters['getWinnersNames'][key]);
                }
            }
            
            return labels;
        },
        overallPieChartColours(){
            let colours = [];
            for(const key in this.$store.getters['getCompetitionWinnerTotals']){
                if(this.$store.getters['getWinnersColours'][key]){
                    colours.push(this.$store.getters['getWinnersColours'][key]) 
                }
            }
            if(colours.length == 0){
                colours.push("#808080")
            }
            return colours;
        },
        overallPieChartImages(){
            let images = [];
            for(const key in this.$store.getters['getCompetitionWinnerTotals']){
                if(this.$store.getters['getWinnersCodes'][key]){
                    images.push(`/assets/gaa/teams/icons/${this.$store.getters['getWinnersCodes'][key]}.WEBP`) 
                } else {
                    images.push('/assets/gaa/teams/chart_icons/draw.jpg')
                }
            }
            return images;
        },
        overallPieChartTotals(){
            let resultTotals = this.$store.getters['getCompetitionWinnerTotals'];
            let charTotals = [];
            for(const key in resultTotals){
                charTotals.push(resultTotals[key]);
            }
            if(charTotals.length == 0){
                charTotals.push(1);
            }
            return charTotals;
        },
        lineChartOptions(){ return {
            chart: {
                id: 'line-chart'
            },
            xaxis: {
                categories: this.getWinningResultsLineData.years
            },
            colors: this.getWinningResultsLineData.data.map(it => it.colour),
            tooltip: {
                x: {
                    format: 'yyyy'
                },
            }
            }
        },
        overallWinnerYearToFilters(){
            let years = ['All'];
            years.push(...this.getWinners.map(it => it.year).sort((a, b) => b - a));
            return years;
        },
        overallWinnerYearFromFilters() {
            let years = ['All'];
            years.push(...this.getWinners.map(it => it.year).sort((a, b) => a - b));
            return years;
        },
        overallCompetitionOptions(){
            return ['Munster', 'Leinster', 'Ulster', 'Connacht', 'All Ireland'];
        }
    },
    mounted(){
        this.fetchWinners("FOOTBALL");
    },
    methods: {
        ...mapActions([
            "fetchWinners", "updateOverallWinnerYearFromFilter","updateOverallWinnerYearToFilter", "updateOverallTotalsYearToFilter", 
            "updateOverallTotalsYearFromFilter", 'updateOverallTeamSelections', "updateOverallCompetitionFilter"
        ]),
        toggleHeadToHeadExpanded(){
            this.loading = true;
            this.headToHeadExpanded = !this.headToHeadExpanded;
            setTimeout(()=>{this.loading = false}, 500);
        },
        toggleOverallExpanded(){
            this.loading = true;
            this.overallExpanded = !this.overallExpanded;
            setTimeout(()=>{this.loading = false}, 500);
        },
        overallTeamSelectionsChanged(){
            this.updateOverallTeamSelections(this.overallTeamSelections);
        },
        overallWinnerYearFromFilterChanged(){
            this.updateOverallWinnerYearFromFilter(this.overallWinnerYearFromFilter);
        },
        overallWinnerYearToFilterChanged(){
            this.updateOverallWinnerYearToFilter(this.overallWinnerYearToFilter);
        },
        overallTotalsYearFromFilterChanged(){
            this.updateOverallTotalsYearFromFilter(this.overallTotalsYearFromFilter);
        },
        overallTotalsYearToFilterChanged(){
            this.updateOverallTotalsYearToFilter(this.overallTotalsYearToFilter);
        },
        overallCompetitionFilterChanged(){
            this.updateOverallCompetitionFilter(this.overallCompetitionFilter);
        }
    }
  }
</script>  
<style scoped>
    .chartBackground {
        background-image: url('/assets/logo/ktg_green_fade.svg');
        background-size: contain;
        background-position: center;
    }

    .img_border {
        border-style: solid;
        border-width: 1px;
    }
</style>